import * as echarts from 'echarts'
const BAR_OPTINO = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  grid: {
    top: 60, // 距离容器上边界40像素
    bottom: 80, // 距离容器下边界30像素
    left: 60,
    right: 30
  },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        color: '#555555'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#0E8BFF'
      }
    },
    data: []
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    splitLine: {
      lineStyle: { color: '#505050' }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#fff'
      }
    }
  },
  series: [
    {
      data: [],
      type: 'bar',
      barWidth: '20%',
      label: {
        show: true, // 是否展示
        position: 'top',
        textStyle: {
          color: '#ffffff',
          // fontWeight: 'bolder',
          fontSize: '12',
          fontFamily: '微软雅黑'
        }
      },
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#1166FF' // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#00A2FF' // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        },
        // 柱形图圆角，初始化效果
        barBorderRadius: [10, 10, 0, 0]
      }
    }
  ]
}
const BAR_ROW_OPTINO = {
  trigger: 'axis',
  axisPointer: {
    type: 'cross',
    label: {
      backgroundColor: '#6a7985'
    }
  },
  grid: {
    top: 40, // 距离容器上边界40像素
    bottom: 80, // 距离容器下边界30像素
    left: 70,
    right: 30
  },
  xAxis: {
    show: false
  },
  yAxis: {
    type: 'category',
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#0E8BFF'
      }
    },
    data: []
  },
  series: {
    data: [],
    type: 'bar',
    barWidth: '40%',
    label: {
      show: true, // 是否展示
      position: 'right',
      textStyle: {
        color: '#ffffff',
        // fontWeight: 'bolder',
        fontSize: '12',
        fontFamily: '微软雅黑'
      }
    },
    itemStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: '#1166FF' // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#00A2FF' // 100% 处的颜色
          }
        ],
        global: false // 缺省为 false
      },
      // 柱形图圆角，初始化效果
      barBorderRadius: 5
    }
  }
}
const PIE_OPTINO = {
  color: [],
  tooltip: {
    trigger: 'item'
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    right: 20,
    top: 20,
    bottom: 20,
    textStyle: {
      color: '#fff'
    }
  },
  series: [
    {
      type: 'pie',
      radius: '70%',
      center: ['45%', '45%'],
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 20,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}
const LINE_OPTINO = {
  color: [],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  legend: {
    top: 20,
    textStyle: {
      color: '#fff'
    },
    data: []
  },
  grid: {
    top: 60, // 距离容器上边界40像素
    bottom: 80, // 距离容器下边界30像素
    left: 50,
    right: 30
  },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        color: '#555555'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#0E8BFF'
      }
    },
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    splitLine: {
      lineStyle: { color: '#505050' }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#fff'
      }
    }
  },
  series: [
    // {
    //   name: 'Line 1',
    //   type: 'line',
    //   stack: '总量',
    //   smooth: true,
    //   lineStyle: {
    //     width: 0
    //   },
    //   showSymbol: false,
    //   areaStyle: {
    //     opacity: 0.8,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [
    //         {
    //           offset: 0,
    //           color: 'rgba(128, 255, 165)' // 0% 处的颜色
    //         },
    //         {
    //           offset: 1,
    //           color: 'rgba(1, 191, 236)' // 100% 处的颜色
    //         }
    //       ],
    //       global: false // 缺省为 false
    //     }
    //   },
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [140, 232, 101, 264, 90, 340, 250]
    // },
    // {
    //   name: 'Line 2',
    //   type: 'line',
    //   stack: '总量',
    //   smooth: true,
    //   lineStyle: {
    //     width: 0
    //   },
    //   showSymbol: false,
    //   areaStyle: {
    //     opacity: 0.8,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [
    //         {
    //           offset: 0,
    //           color: '#39FFDC' // 0% 处的颜色
    //         },
    //         {
    //           offset: 1,
    //           color: '#1C77D8' // 100% 处的颜色
    //         }
    //       ],
    //       global: false // 缺省为 false
    //     }
    //   },
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [120, 282, 111, 234, 220, 340, 310]
    // },
    // {
    //   name: 'Line 3',
    //   type: 'line',
    //   stack: '总量',
    //   smooth: true,
    //   lineStyle: {
    //     width: 0
    //   },
    //   showSymbol: false,
    //   areaStyle: {
    //     opacity: 0.8,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [
    //         {
    //           offset: 0,
    //           color: '#1166FF' // 0% 处的颜色
    //         },
    //         {
    //           offset: 1,
    //           color: '#00A2FF' // 100% 处的颜色
    //         }
    //       ],
    //       global: false // 缺省为 false
    //     }
    //   },
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [320, 132, 201, 334, 190, 130, 220]
    // }
  ]
}
const LINE_OPTINO_SmoothFalse = {
  color: [],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  grid: {
    top: 40, // 距离容器上边界40像素
    bottom: 80, // 距离容器下边界30像素
    left: 50,
    right: 30
  },
  // legend: {
  //   top: 20,
  //   textStyle: {
  //     color: '#fff'
  //   },
  //   data: []
  // },
  xAxis: {
    axisLine: {
      lineStyle: {
        color: '#555555'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#0E8BFF'
      }
    },
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    splitLine: {
      lineStyle: { color: '#505050' }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#fff'
      }
    }
  },
  series: [
    {
      name: '',
      type: 'line',
      lineStyle: {
        width: 0
      },
      areaStyle: {
        opacity: 0.8,
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#80FFA5' // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#0B103E' // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        }
      },
      emphasis: {
        focus: 'series'
      },
      data: []
    },
    {
      name: '',
      type: 'line',
      lineStyle: {
        width: 0
      },
      areaStyle: {
        opacity: 0.8,
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#00DDFF' // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#0B103E' // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        }
      },
      data: []
    }
    // {
    //   name: '视频广告',
    //   type: 'line',
    //   lineStyle: {
    //     width: 0
    //   },
    //   areaStyle: {
    //     opacity: 0.8,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [
    //         {
    //           offset: 0,
    //           color: '#37A2FF' // 0% 处的颜色
    //         },
    //         {
    //           offset: 1,
    //           color: '#0B103E' // 100% 处的颜色
    //         }
    //       ],
    //       global: false // 缺省为 false
    //     }
    //   },
    //   data: [150, 232, 201, 154, 190, 330, 410, 332, 301, 334, 390, 330]
    // },
    // {
    //   name: '直接访问',
    //   type: 'line',
    //   lineStyle: {
    //     width: 0
    //   },
    //   areaStyle: {
    //     opacity: 0.8,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [
    //         {
    //           offset: 0,
    //           color: '#FF0087' // 0% 处的颜色
    //         },
    //         {
    //           offset: 1,
    //           color: '#0B103E' // 100% 处的颜色
    //         }
    //       ]
    //       // global: false // 缺省为 false
    //     }
    //   },
    //   data: [320, 332, 301, 334, 390, 330, 320, 932, 901, 934, 1290, 1330]
    // }
  ]
}
const WINDDIRECTION = {
  title: {
    text: '',
    left: 'center',
    top: '20%'
  },
  grid: {
    left: '5%',
    top: '16%',
    right: '5%',
    bottom: '22%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'cross' }
  },
  dataZoom: [
    {
      bottom: '8%',
      start: 0,
      end: 100
    },
    {
      type: 'inside'
    }
  ],
  xAxis: {
    boundaryGap: false,
    axisLabel: {
      textStyle: { color: '#ffffff', fontSize: 12 }
    },
    axisLine: {
      lineStyle: { color: '#707070' }
    },
    data: []
  },
  yAxis: {
    name: '风速(m/s)',
    nameGap: 35,
    nameTextStyle: { color: '#ffffff' },
    axisLabel: {
      textStyle: { color: '#ffffff', fontSize: 12 }
    },
    splitLine: {
      lineStyle: { color: '#DBDBDB' }
    },
    axisLine: { show: false },
    axisTick: { show: false }
  },
  series: [
    {
      type: 'line',
      color: '#FFE282',
      smooth: true,
      symbol: '',
      symbolSize: 20,
      // -- 渐变 v1 --
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
            offset: 0,
            color: 'rgba(168,45,255, 0.9)'
          }, {
            offset: 1,
            color: 'rgba(26,170,255,0.4)'
          }],
          globalCoord: false
        }
      },

      // -- 渐变 v2 --
      // areaStyle: {
      //   // 修改 x1,y1,x,y 得到不同的渐变方式
      //   color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
      //     {
      //       offset: 0,
      //       color: 'rgba(137,19,227, 0.8)',
      //     },
      //     {
      //       offset: 1,
      //       color: 'rgba(0,255,255,0.2)',
      //     },
      //   ]),
      // },
      lineStyle: {
        color: {
          // 修改 x,y,x1,y1 得到不同的渐变方式
          type: 'linear',
          x: 1,
          y: 0,
          x2: 0,
          y2: 0,
          // 0% 处的颜色 // 100% 处的颜色
          colorStops: [
            { offset: 0, color: '#448ee2' },
            { offset: 1, color: '#448ee2' }],

          // color: '#448ee2',
          global: false
        },
        width: 1
      },
      data: []
    }
  ]
}
const BAR_OPTINO_TWO = {
  grid: {
    left: '5%',
    right: '5%',
    bottom: '15%',
    top: '10%',
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    formatter: '时间：{b0}<br /> 光照：{c0}KLux'

  },
  xAxis: {
    show: true,
    type: 'value',
    axisLine: {
      show: true
    },
    axisLabel: {
      show: true,
      formatter: '{value} KLux'
    },
    splitLine: {
      show: true,
      lineStyle: {
        // type: 'dashed'
      }
    },
    axisTick: {
      show: false
    }
  },
  yAxis: [
    {
      type: 'category',
      inverse: false,
      nameTextStyle: {
        color: '#ffffff'
      },
      show: true,
      textStyle: {
        color: '#ffffff'
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: true,
        color: '#ffffff'
      },
      data: []
    }

  ],
  series: [
    {
      name: '光照',
      type: 'bar',
      zlevel: 1,
      label: {
        normal: {
          show: true,
          position: 'right',
          textStyle: {
            color: '#ffffff'
          }
        }
      },
      itemStyle: {
        normal: {
          barBorderRadius: 0,
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 0,
              color: 'rgb(254,65,27,1)'
            },
            {
              offset: 1,
              color: 'rgb(254,155,26,1)'
            }
          ])
        }
      },
      barWidth: 20,
      data: []
    }
  ]
}
const BAR_OPTINO_3D = {
  // backgroundColor: '#010243',
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: 'shadow'// 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '时间：{b0}<br /> 降水量：{c0}mm'
  },
  grid: {
    left: '3%',
    right: '5%',
    bottom: '15%',
    top: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLine: {
      show: true,
      lineStyle: {
        color: '#15205B'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      // interval: 0,
      // rotate: 40,
      textStyle: {
        fontFamily: 'Microsoft YaHei',
        color: '#fff', // x轴颜色
        fontWeight: 'normal'
      },
      interval: 0//标签设置为全部显示
    }
  },

  yAxis: {
    name: '',
    min: 0,
    max: 0,
    type: 'value',
    nameTextStyle: {
      color: '#fff  '
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: '#15205B'
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(87,206,234,0.1)' //左侧显示线
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      formatter: '{value}mm',
      textStyle: {
        color: '#fff'
      }
    }
  },
  series: [
    //中间形状
    {
      type: 'pictorialBar',
      symbolSize: [25, 8],
      symbolOffset: [0, -4],
      symbolPosition: 'end',
      z: 12,
      color: '#1bdffc',
      data: []
    },
    //底部形状
    {
      name: '',
      type: 'pictorialBar',
      symbolSize: [25, 8],
      symbolOffset: [0, 4],
      color: '#1251d2',
      z: 12,
      data: []
    },
    //上部形状
    {
      // data: [100, 100, 100, 100, 100 ,100],
      data: [],
      type: 'pictorialBar',
      symbolSize: [25, 8],
      symbolOffset: [0, -4],
      color: '#283190',
      symbolPosition: 'end'
    },
    {
      name: '',
      type: 'bar',
      stack: '1',
      barWidth: 25,
      itemStyle: {
        normal: {
          opacity: 0.7,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#1bdffc'
            },
            {
              offset: 1,
              color: '#1251d2'
            }
          ]),
          barBorderRadius: 0
        }
      },
      label: {
        show: false,
        position: ['18', '-18'],
        color: '#00f8ff',
        fontSize: 12
      },
      data: []
    },
    //底部柱子
    {
      data: [],
      type: 'bar',
      barWidth: 25,
      stack: '1',
      zlevel: -1,
      itemStyle: {
        normal: {
          opacity: 0.7,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#182075'
            },
            {
              offset: 1,
              color: '#182075'
            }
          ]),
          barBorderRadius: 0
        }
      }
    }
  ]
}

export {
  BAR_OPTINO,
  BAR_ROW_OPTINO,
  PIE_OPTINO,
  LINE_OPTINO,
  LINE_OPTINO_SmoothFalse,
  WINDDIRECTION,
  BAR_OPTINO_TWO,
  BAR_OPTINO_3D
}
